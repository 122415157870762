body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.CarInfoAmount {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.CarInfo {
  width: 100%;
}
.Amount {
  color: #16667d;
}
.CarInfoValue {
  color: #16667d;
  float: right;
  width: 100%;
}
.TextRight {
  text-align: end;
}
.DataGrid:hover {
  cursor: pointer;
}
.Hand:hover {
  cursor: copy;
}
.CarPicture:hover {
  cursor: pointer;
}
.customNextArrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.customPrevArrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.sliderWrapper {
  position: relative;
}
.sliderSlick {
  padding-left: 25px;
}
.sliderWrapperGratitude {
  position: relative;
  width: 90%;
  height: 90%;
  overflow: hidden;
}
.sliderWrapperGratitudeItem {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sliderWrapperGratitudeItem img {
  object-fit: contain !important; /* або object-fit: cover, залежно від ваших потреб */
}
.customNextArrowGratitude {
  position: absolute;
  top: 5%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.customPrevArrowGratitude {
  position: absolute;
  top: 5%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
}
.warningMsg {
  color: #e54e07;
}
